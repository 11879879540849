* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/*
html, body {
  height: 100%;
}
*/
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
}

header {
  text-align: center;
}
header .logo {
  float: left;
}
.greeting {
  position: absolute;
  top: 1.0rem;
  right: 2.5rem;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

h1, h2 {
  font-size: 2rem;
  text-align: center;
  font-weight: normal;
  padding: 0.5rem 0 0 0;
}

.main-controls {
  padding: 0.5rem 0;
}

canvas {
  display: block;
  margin-bottom: 0.5rem;
}

#buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#buttons button {
  font-size: 1rem;
  padding: 1rem;
  width: calc(50% - 0.25rem);
}

button {
  font-size: 1rem;
  background: #0088cc;
  text-align: center;
  color: white;
  border: none;
  transition: all 0.2s;
  padding: 0.5rem;
}

button:hover, button:focus {
  box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 1);
  background: #0ae;
}

button:active {
  box-shadow: inset 0px 0px 20px rgba(0,0,0,0.5);
  transform: translateY(2px);
}


/* Make the clips use as much space as possible, and
 * also show a scrollbar when there are too many clips to show
 * in the available space */
.sound-clips {
  flex: 1;
  overflow: auto;
}

section, article {
  display: block;
}

.clip {
  padding-bottom: 1rem;
}

audio {
  width: 100%;
  display: block;
  margin: 1rem auto 0.5rem;
}

.clip p {
  display: inline-block;
  font-size: 1rem;
}

.clip button {
  font-size: 1rem;
  float: right;
}

.messageNeutral {
  color: blue;
}

.messageError {
 color: red;
}

.messageSuccess {
 color: green;
}

button.delete {
  background: #f00;
  padding: 0.5rem 0.75rem;
  font-size: 0.8rem;
}

/* Checkbox hack to control information box display */

label {
  font-size: 1.5rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 5;
  cursor: pointer;
  background-color: #0088cc;
  border-radius: 5px;
}

input[type=checkbox] {
   position: absolute;
   top: -100px;
}

aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  transition: 0.3s all ease-out;
  background-color: #efefef;
  padding: 1rem;
  overflow:scroll;
}

aside p {
  font-size: 1rem;
  margin: 0.5rem 0;
}

aside a {
  color: #666;
}

aside section {
  margin: 2rem 5rem;
}

/* Toggled State of information box */
input[type=checkbox]:checked ~ aside {
  transform: translateX(0);
}

/* Cursor when clip name is clicked over */

.clip p {
  cursor: pointer;
}

.circle{ 
  width:20px; 
  height:20px; 
  border-radius: 50%;
  background: white;  
  position: absolute;
}

.square {
  width: 20px;
  height: 20px;
  background: white;
  position: absolute;
}

/* Adjustments for wider screens */
@media all and (min-width: 800px) {
  /* Don't take all the space as readability is lost when line length goes past a certain size */
  .wrapper {
    width: 60%;
    max-width: 1000px;
    margin: 0.5rem auto;
  }  
}